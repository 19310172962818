<template>
  <div>
    <div
      v-if="errorMessage"
      class="err-message d-flex justify-content-center align-items-center"
    >
      <h1 class="h3">{{ errorMessage }}</h1>
    </div>
    <parcel-tracking
      v-if="isParcelTracking && parcel"
      :parcel="parcel"
      :loading="loading"
    />
    <div v-else-if="service || destinationPackage" class="container">
      <div class="py-4 pt-md-5">
        <b-row>
          <b-col md="5">
            <div class="h-100 d-flex flex-column justify-content-between">
              <destination-tracking
                v-if="isDestinationTracking"
                :destination-package="destinationPackage"
              />
              <multi-point-tracking v-else :service="service" />
            </div>
          </b-col>
          <b-col v-if="!isParcelTracking">
            <driver-info :driver="driver" :show-phone="!isTrackingFinished" />
            <div v-if="isServiceTracking" class="map-wrapper mt-3">
              <z-map
                class="map"
                :destinations="destinations"
                :driver="mapDriver"
              />
            </div>

            <router-link to="/">
              <div class="explore-zubut d-md-none">
                <img
                  class="w-100 mt-3"
                  src="@/assets/img/explore-zubut-banner.png"
                  alt="Explora zubut"
                />
              </div>
            </router-link>
          </b-col>
        </b-row>
      </div>
    </div>
    <notifications group="notifications" />
  </div>
</template>

<script>
import Destinations from "@/services/destinations";
import Services from "@/services/services";
import Parcels from "@/services/parcels";
import ZMap from "@zubut/common/src/components/ZMap";
import { makeShortUUID } from "@/utils/strings";
import PackageProgress from "@/constants/package/progress";
import ServiceStatus from "@/constants/services/status";
import TrackingType from "@/constants/tracking/type";
import DriverInfo from "./DriverInfo";
import DestinationTracking from "./DestinationTracking";
import MultiPointTracking from "./MultiPointTracking";
import ParcelTracking from "./ParcelTracking";

export default {
  name: "Tracking",

  components: {
    DestinationTracking,
    DriverInfo,
    MultiPointTracking,
    ParcelTracking,
    ZMap
  },

  data() {
    return {
      loading: false,
      driver: null,
      destinationPackage: null,
      service: null,
      requestInterval: null,
      errorMessage: null,
      parcelLogs: [],
      deliveryDate: null,
      trackingType: TrackingType.NUM_PARCEL,
      parcel: null
    };
  },

  computed: {
    serviceId() {
      return this.service ? makeShortUUID(this.service.id) : "";
    },
    destinations() {
      if (this.service) {
        const destination =
          this.service.nextDestination || this.service.lastCheckpoint;
        let destinations = [this.service.origin];
        if (destination) destinations.push(destination);
        return destinations;
      }
      return [];
    },
    isDestinationTracking() {
      return this.trackingType === TrackingType.NUM_PACKAGE;
    },
    isParcelTracking() {
      return this.trackingType === TrackingType.NUM_PARCEL;
    },
    isServiceTracking() {
      return this.trackingType === TrackingType.NUM_SERVICE;
    },
    isServiceFinished() {
      return this.service
        ? ServiceStatus.NUM_FINISHED === this.service.status
        : false;
    },
    isDestinationFinished() {
      return this.destinationPackage
        ? this.destinationPackage.progress === PackageProgress.NUM_DELIVERED ||
            this.destinationPackage.progress === PackageProgress.NUM_RETURN
        : false;
    },
    isTrackingFinished() {
      return this.isServiceFinished && this.isDestinationFinished;
    },
    mapDriver() {
      if (!this.isServiceFinished) {
        return this.driver;
      }
      return null;
    },
    showDriverInfo() {
      return !this.isParcelTracking;
    }
  },

  mounted() {
    this.getTrackingInfo();
    this.reloadService();
  },

  beforeDestroy() {
    clearInterval(this.requestInterval);
  },

  methods: {
    reloadService() {
      this.requestInterval = setInterval(() => {
        this.getTrackingInfo();
      }, 30000);
    },
    getTrackingInfo() {
      const trackingType = this.$route.params.type
        ? TrackingType.get[this.$route.params.type]
        : TrackingType.NUM_SERVICE;

      if (trackingType === TrackingType.NUM_PACKAGE) {
        this.trackingType = TrackingType.NUM_PACKAGE;
        this.getDestinationInfo();
      } else if (trackingType === TrackingType.NUM_PARCEL) {
        this.trackingType = TrackingType.NUM_PARCEL;
        this.getParcelInfo();
      } else {
        this.trackingType = TrackingType.NUM_SERVICE;
        this.getServiceInfo();
      }
    },
    getServiceInfo() {
      this.loading = true;
      const serviceId = this.$route.params.trackingId;
      this.$emit("getId", serviceId);
      Services.trackingInfo(serviceId)
        .then(res => {
          this.driver = res.driver;
          this.service = res.service;

          if (this.isServiceFinished) {
            clearInterval(this.requestInterval);
          }
        })
        .catch(err => {
          if (err.statusCode === 422) {
            this.errorMessage = "El URL de tracking no es válido";
          } else if (err.statusCode === 400) {
            this.errorMessage =
              "No se tiene información de tracking para este servicio por el momento";
          } else {
            this.errorMessage =
              "Ocurrió un error al intentar obtener la información de tracking del servicio";
          }
          this.$captureError(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getDestinationInfo() {
      this.loading = true;
      const trackingId = this.$route.params.trackingId;
      this.$emit("getId", trackingId);
      Destinations.trackingInfo({ trackingId })
        .then(res => {
          this.destinationPackage = res.package;
          this.driver = res.driver;

          if (this.isDestinationFinished) {
            clearInterval(this.requestInterval);
          }
        })
        .catch(err => {
          if (err.statusCode === 422) {
            this.errorMessage = "El URL de tracking no es válido";
          } else if (err.statusCode === 400) {
            this.errorMessage =
              "No se tiene información de tracking para este servicio por el momento";
          } else {
            this.errorMessage =
              "Ocurrió un error al intentar obtener la información de tracking del servicio";
          }
          this.$captureError(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getParcelInfo() {
      this.loading = true;
      const trackingId = this.$route.params.trackingId;
      this.$emit("getId", trackingId);
      Parcels.trackingInfo(trackingId)
        .then(res => {
          this.parcel = res;
        })
        .catch(err => {
          if (err.statusCode === 422) {
            this.errorMessage = "El URL de tracking no es válido";
          } else if (err.statusCode === 400) {
            this.errorMessage =
              "No se tiene información de tracking para este paquete por el momento";
          } else {
            this.errorMessage =
              "Ocurrió un error al intentar obtener la información de tracking del paquete";
          }
          this.$captureError(err);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.map-wrapper {
  border-radius: 6px;
  overflow: hidden;
}

.map {
  height: 300px;
}

.err-message {
  min-height: 600px;
}

.header {
  background-color: $prussian-blue;
  color: white;
}

@media (min-width: 769px) {
  .map {
    height: 500px;
  }

  .explore-zubut {
    position: relative;

    button {
      position: absolute;
      right: 1em;
      top: calc(50% - 8px);
      border-radius: 4px;
      font-weight: 700;
    }
  }
}
</style>

const MOTORCYCLE = "Motocicleta";
const CAR = "Automóvil";
const ECO = "Eco";

const NUM_MOTORCYCLE = 0;
const NUM_CAR = 1;
const NUM_ECO = 2;

const get = {};
get[NUM_MOTORCYCLE] = MOTORCYCLE;
get[NUM_CAR] = CAR;
get[NUM_ECO] = ECO;

const options = [
  { value: NUM_MOTORCYCLE, text: MOTORCYCLE },
  { value: NUM_CAR, text: CAR },
  { value: NUM_ECO, text: ECO }
];

export default {
  get,
  options,
  MOTORCYCLE,
  CAR,
  ECO,
  NUM_MOTORCYCLE,
  NUM_CAR,
  NUM_ECO
};

import DropdownOption from "@zubut/common/src/models/dropdown-option";

const PENDING_COLLECTION = "Pendiente de recolección";
const COLLECTED = "Recolectado camino a bodega";
const IN_WAREHOUSE = "En bodega";
const SCANNED = "Escaneado";
const SENT = "Enviado";
const DELIVERED = "Entregado";
const DELIVERY_ATTEMPT = "Intento de entrega camino a bodega";
const PENDING_RETURN_TO_ORIGIN = "Pendiente regreso al origen";
const RETURNING_TO_ORIGIN = "De regreso al origen";
const RETURNING_TO_CLIENT_WAREHOUSE = "De regreso a bodega del cliente";
const RETURNED_TO_ORIGIN = "Regresado al origen";
const CANCELLED = "Cancelado";
const LOST = "Perdido";

const NUM_PENDING_COLLECTION = 0;
const NUM_COLLECTED = 1;
const NUM_IN_WAREHOUSE = 2;
const NUM_SCANNED = 3;
const NUM_SENT = 4;
const NUM_DELIVERED = 5;
const NUM_DELIVERY_ATTEMPT = 6;
const NUM_PENDING_RETURN_TO_ORIGIN = 7;
const NUM_RETURNING_TO_ORIGIN = 8;
const NUM_RETURNING_TO_CLIENT_WAREHOUSE = 9;
const NUM_RETURNED_TO_ORIGIN = 10;
const NUM_CANCELLED = 11;
const NUM_LOST = 12;

const get: any = {};
get[NUM_PENDING_COLLECTION] = PENDING_COLLECTION;
get[NUM_COLLECTED] = COLLECTED;
get[NUM_IN_WAREHOUSE] = IN_WAREHOUSE;
get[NUM_SCANNED] = SCANNED;
get[NUM_SENT] = SENT;
get[NUM_DELIVERED] = DELIVERED;
get[NUM_DELIVERY_ATTEMPT] = DELIVERY_ATTEMPT;
get[NUM_PENDING_RETURN_TO_ORIGIN] = PENDING_RETURN_TO_ORIGIN;
get[NUM_RETURNING_TO_ORIGIN] = RETURNING_TO_ORIGIN;
get[NUM_RETURNING_TO_CLIENT_WAREHOUSE] = RETURNING_TO_CLIENT_WAREHOUSE;
get[NUM_RETURNED_TO_ORIGIN] = RETURNED_TO_ORIGIN;
get[NUM_CANCELLED] = CANCELLED;
get[NUM_LOST] = LOST;

const inProgressStatus = [
  NUM_COLLECTED,
  NUM_IN_WAREHOUSE,
  NUM_SCANNED,
  NUM_SENT,
  NUM_DELIVERY_ATTEMPT,
  NUM_PENDING_RETURN_TO_ORIGIN,
  NUM_RETURNING_TO_ORIGIN,
  NUM_RETURNING_TO_CLIENT_WAREHOUSE
];

const options: DropdownOption[] = Object.keys(get).map(key => ({
  text: get[key],
  value: parseInt(key)
}));

export default {
  get,
  options,
  inProgressStatus,
  NUM_PENDING_COLLECTION,
  NUM_COLLECTED,
  NUM_IN_WAREHOUSE,
  NUM_SCANNED,
  NUM_SENT,
  NUM_DELIVERED,
  NUM_DELIVERY_ATTEMPT,
  NUM_PENDING_RETURN_TO_ORIGIN,
  NUM_RETURNING_TO_ORIGIN,
  NUM_RETURNING_TO_CLIENT_WAREHOUSE,
  NUM_RETURNED_TO_ORIGIN,
  NUM_CANCELLED,
  NUM_LOST,
  PENDING_COLLECTION,
  COLLECTED,
  IN_WAREHOUSE,
  SCANNED,
  SENT,
  DELIVERED,
  DELIVERY_ATTEMPT,
  PENDING_RETURN_TO_ORIGIN,
  RETURNING_TO_ORIGIN,
  RETURNING_TO_CLIENT_WAREHOUSE,
  RETURNED_TO_ORIGIN,
  LOST,
  CANCELLED
};

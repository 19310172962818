<template>
  <div>
    <div v-if="destinationPackageId" class="font-weight-bold">
      ID: {{ destinationPackageId }}
    </div>
    <div class="mt-4">
      <div class="h2 font-weight-bold">
        {{ progressText }}
      </div>
      <div
        v-show="!isServiceFinished"
        class="text-secondary font-weight-semi-bold"
      >
        Tu pedido llega hoy antes de las 8:00 PM
      </div>
    </div>
    <div class="mt-4 ml-5">
      <div class="checkpoint text-secondary" :class="[originStateClass]">
        <div class="dot"></div>
        <div class="line"></div>
        <div>
          <div class="font-weight-bold">
            {{ packageProgress.PREPARING }}
          </div>
        </div>
        <div
          v-show="destinationPackage.progress === packageProgress.NUM_PREPARING"
        >
          Esperando al mensajero
        </div>
      </div>

      <div class="checkpoint mt-3 text-secondary" :class="[pickedStateClass]">
        <div class="dot"></div>
        <div class="line"></div>
        <div>
          <div class="font-weight-bold">
            {{ packageProgress.PICKED }}
          </div>
        </div>
        <div class="mt-2 d-flex">
          <div>
            {{ collectedAt }}
          </div>
          <div class="ml-2">
            El mensajero llegó a sucursal
          </div>
        </div>
      </div>

      <div class="checkpoint mt-3 text-secondary" :class="[otwStateClass]">
        <div class="dot"></div>
        <div class="line"></div>
        <div>
          <div class="font-weight-bold">
            {{ packageProgress.DELIVERING }}
          </div>
        </div>
        <div class="mt-2 d-flex">
          <div>
            {{ otwTime }}
          </div>
          <div class="ml-2">
            Tu pedido salió de sucursal
          </div>
        </div>
      </div>

      <div
        class="checkpoint mt-3 text-secondary"
        :class="{ visited: isServiceFinished }"
      >
        <div class="dot"></div>
        <div>
          <div class="font-weight-bold">
            {{
              destinationPackage.progress === packageProgress.NUM_RETURN
                ? packageProgress.RETURN
                : packageProgress.DELIVERED
            }}
          </div>
          <div class="d-flex mt-2">
            <div v-show="serviceFinishedTime" class="mr-3">
              {{ serviceFinishedTime }}
            </div>
            <div v-show="packageAddress">
              {{
                destinationPackage.progress === packageProgress.NUM_RETURN
                  ? "El paquete se regresó a sucursal"
                  : packageAddress
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatISO } from "@zubut/common/src/utils/time";
import PackageProgress from "@/constants/package/progress";

export default {
  name: "DestinationTracking",

  props: {
    destinationPackage: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      loading: true,
      packageProgress: PackageProgress
    };
  },

  computed: {
    destinationPackageId() {
      return this.$route.params?.externalId || "";
    },
    collectedAt() {
      const collected = this.destinationPackage?.events?.collected;
      return collected ? formatISO(collected, "h:mm aaa") : "";
    },
    otwTime() {
      const otw = this.destinationPackage?.events?.otw;
      return otw ? formatISO(otw, "h:mm aaa") : "";
    },
    isServiceFinished() {
      return this.destinationPackage
        ? this.destinationPackage.progress === PackageProgress.NUM_DELIVERED ||
            this.destinationPackage.progress === PackageProgress.NUM_RETURN
        : false;
    },
    serviceFinishedTime() {
      const finishTime =
        this.destinationPackage?.events?.delivered ||
        this.destinationPackage?.events?.returned;
      return finishTime ? formatISO(finishTime, "h:mm aaa") : "";
    },
    originStateClass() {
      if (this.destinationPackage.progress === PackageProgress.NUM_PREPARING) {
        return "active";
      }
      return "visited";
    },
    pickedStateClass() {
      if (this.originStateClass === "active") {
        return "";
      }
      if (this.destinationPackage.progress === PackageProgress.NUM_PICKED) {
        return "active";
      }
      return "visited";
    },
    otwStateClass() {
      if (!this.pickedStateClass || this.pickedStateClass === "active") {
        return "";
      }
      if (this.destinationPackage.progress === PackageProgress.NUM_DELIVERING) {
        return "active";
      }
      return "visited";
    },
    hasArrivedToOrigin() {
      if (
        this.destinationPackage?.progress !== PackageProgress.NUM_PREPARING ||
        this.isServiceFinished
      ) {
        return true;
      }
      return false;
    },
    packageAddress() {
      return this.destinationPackage?.address;
    },
    progressText() {
      return this.destinationPackage
        ? PackageProgress.get[this.destinationPackage.progress]
        : PackageProgress.PREPARING;
    }
  }
};
</script>

<style lang="scss" scoped>
.checkpoint {
  position: relative;

  .dot {
    position: absolute;
    top: 5px;
    left: calc(-1.5em - 10px);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: $gainsboro;
  }

  .line {
    position: absolute;
    height: calc(100% + 5px);
    width: 2px;
    background-color: $gainsboro;
    top: 15px;
    left: calc(-1.5em - 6px);
  }
}

.checkpoint.active {
  color: $nero !important;
  .dot {
    top: 0px;
    left: calc(-1.5em - 14px);
    width: 18px;
    height: 18px;
    border: 2px solid rgba(1, 119, 253, 0.5);
    background: transparent;

    &::after {
      position: absolute;
      content: "";
      top: 2px;
      left: 2px;
      width: 10px;
      height: 10px;
      background: var(--primary);
      border-radius: 50%;
    }
  }

  .line {
    top: 18px;
    height: calc(100% + 1px);
  }
}

.checkpoint.visited {
  .dot,
  .line {
    background: var(--primary);
  }
}
</style>

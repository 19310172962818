const PREPARING = "Preparando pedido";
const PICKED = "Pedido recolectado";
const DELIVERING = "Pedido en camino";
const DELIVERED = "Entregado";
const RETURN = "Regresado";

const NUM_PREPARING = 0;
const NUM_PICKED = 1;
const NUM_DELIVERING = 2;
const NUM_DELIVERED = 3;
const NUM_RETURN = 4;

const get = {};
get[NUM_PREPARING] = PREPARING;
get[NUM_PICKED] = PICKED;
get[NUM_DELIVERING] = DELIVERING;
get[NUM_DELIVERED] = DELIVERED;
get[NUM_RETURN] = RETURN;

export default {
  get,
  PREPARING,
  PICKED,
  DELIVERING,
  DELIVERED,
  RETURN,
  NUM_PREPARING,
  NUM_PICKED,
  NUM_DELIVERING,
  NUM_DELIVERED,
  NUM_RETURN
};

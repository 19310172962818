const PACKAGE_REQUEST = "Solicitud de paquete";
const COLLECTED = "Recolección de paquete";
const COLLECTION_ATTEMPT = "Intento de recolección";
const DELIVERED_IN_WAREHOUSE = "Entrega en bodega";
const SCANNED_BY_DRIVER = "Escaneado por mensajero";
const IN_ROUTE = "Salida a ruta";
const DRIVER_CHANGED = "Cambio de mensajero";
const DELIVERED = "Paquete entregado";
const DELIVERY_ATTEMPT = "Intento de entrega";
const PENDING_RETURN_TO_ORIGIN = "Pendiente por regresar al origen";
const DELIVERED_IN_ORIGIN = "Paquete entregado en el origen";
const RETURNING_TO_WAREHOUSE = "Regresado a bodega";
const RETURN_START = "Devolución iniciada";
const CANCELLED_BY_EXCEDED = "Cancelado por exceso de intentos de recolección";
const CANCELLED_BY_CLIENT = "Cancelado por el cliente";
const CANCELLED_BY_ZUBUT = "Cancelado por Zubut";

const NUM_PACKAGE_REQUEST = 0;
const NUM_COLLECTED = 1;
const NUM_COLLECTION_ATTEMPT = 2;
const NUM_DELIVERED_IN_WAREHOUSE = 3;
const NUM_SCANNED_BY_DRIVER = 4;
const NUM_IN_ROUTE = 5;
const NUM_DRIVER_CHANGED = 6;
const NUM_DELIVERED = 7;
const NUM_DELIVERY_ATTEMPT = 8;
const NUM_PENDING_RETURN_TO_ORIGIN = 9;
const NUM_DELIVERED_IN_ORIGIN = 10;
const NUM_RETURNING_TO_WAREHOUSE = 11;
const NUM_RETURN_START = 12;
const NUM_CANCELLED_BY_EXCEDED = 13;
const NUM_CANCELLED_BY_CLIENT = 14;
const NUM_CANCELLED_BY_ZUBUT = 15;

const get = {};
get[NUM_PACKAGE_REQUEST] = PACKAGE_REQUEST;
get[NUM_COLLECTED] = COLLECTED;
get[NUM_COLLECTION_ATTEMPT] = COLLECTION_ATTEMPT;
get[NUM_DELIVERED_IN_WAREHOUSE] = DELIVERED_IN_WAREHOUSE;
get[NUM_SCANNED_BY_DRIVER] = SCANNED_BY_DRIVER;
get[NUM_IN_ROUTE] = IN_ROUTE;
get[NUM_DRIVER_CHANGED] = DRIVER_CHANGED;
get[NUM_DELIVERED] = DELIVERED;
get[NUM_DELIVERY_ATTEMPT] = DELIVERY_ATTEMPT;
get[NUM_PENDING_RETURN_TO_ORIGIN] = PENDING_RETURN_TO_ORIGIN;
get[NUM_DELIVERED_IN_ORIGIN] = DELIVERED_IN_ORIGIN;
get[NUM_RETURNING_TO_WAREHOUSE] = RETURNING_TO_WAREHOUSE;
get[NUM_RETURN_START] = RETURN_START;
get[NUM_CANCELLED_BY_EXCEDED] = CANCELLED_BY_EXCEDED;
get[NUM_CANCELLED_BY_CLIENT] = CANCELLED_BY_CLIENT;
get[NUM_CANCELLED_BY_ZUBUT] = CANCELLED_BY_ZUBUT;

const options = Object.keys(get).map(key => ({
  text: get[key],
  value: parseInt(key)
}));

export default {
  get,
  options,
  NUM_PACKAGE_REQUEST,
  NUM_COLLECTED,
  NUM_COLLECTION_ATTEMPT,
  NUM_DELIVERED_IN_WAREHOUSE,
  NUM_SCANNED_BY_DRIVER,
  NUM_IN_ROUTE,
  NUM_DRIVER_CHANGED,
  NUM_DELIVERED,
  NUM_DELIVERY_ATTEMPT,
  NUM_PENDING_RETURN_TO_ORIGIN,
  NUM_DELIVERED_IN_ORIGIN,
  NUM_RETURNING_TO_WAREHOUSE,
  NUM_RETURN_START,
  NUM_CANCELLED_BY_EXCEDED,
  NUM_CANCELLED_BY_CLIENT,
  NUM_CANCELLED_BY_ZUBUT,
  PACKAGE_REQUEST,
  COLLECTED,
  COLLECTION_ATTEMPT,
  DELIVERED_IN_WAREHOUSE,
  SCANNED_BY_DRIVER,
  IN_ROUTE,
  DRIVER_CHANGED,
  DELIVERED,
  DELIVERY_ATTEMPT,
  PENDING_RETURN_TO_ORIGIN,
  DELIVERED_IN_ORIGIN,
  RETURNING_TO_WAREHOUSE,
  RETURN_START,
  CANCELLED_BY_EXCEDED,
  CANCELLED_BY_CLIENT,
  CANCELLED_BY_ZUBUT
};

const NUM_OWNER = 0;
const NUM_GUARD = 1;
const NUM_RELATIVE = 2;
const NUM_NEIGHOUR = 3;

const OWNER = "Titular";
const GUARD = "Guardia";
const RELATIVE = "Pariente";
const NEIGHOUR = "Vecino";

const get = {};
get[NUM_OWNER] = OWNER;
get[NUM_GUARD] = GUARD;
get[NUM_RELATIVE] = RELATIVE;
get[NUM_NEIGHOUR] = NEIGHOUR;

export default {
  get,
  NUM_OWNER,
  NUM_GUARD,
  NUM_RELATIVE,
  NUM_NEIGHOUR,
  OWNER,
  GUARD,
  RELATIVE,
  NEIGHOUR
};

<template>
  <div class="driver-contact mt-5 mt-md-0 border rounded p-3">
    <div class="d-flex justify-content-between">
      <div class="d-flex">
        <div class="p-2 rounded bg-light">
          <font-awesome-icon icon="helmet" flip="horizontal" size="2x" />
        </div>
        <div class="ml-3">
          <div class="font-weight-semi-bold">
            {{ isDriverAssigned ? driverName : "Por asignar" }}
          </div>
          <div class="text-secondary">
            <span v-show="vehicleName">{{ vehicleName }} - </span>
            <span v-show="plateNo">{{ plateNo }}</span>
          </div>
        </div>
      </div>
      <a
        v-if="showPhone && driver && driver.phone"
        class="d-flex align-items-center text-main"
        :href="`tel:${driver.phone}`"
      >
        <font-awesome-icon icon="phone-call" size="lg" />
        <div v-if="driver" class="ml-3 font-weight-semi-bold">
          {{ driver.phone }}
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import VehicleTypes from "@/constants/vehicles/type";

export default {
  name: "DriverInfo",

  props: {
    driver: {
      type: Object,
      default: () => {}
    },
    showPhone: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    driverName() {
      if (this.isDriverAssigned) {
        return `${this.driver.name}`;
      }
      return "";
    },
    isDriverAssigned() {
      return this.driver?.name ? true : false;
    },
    plateNo() {
      return this.driver?.vehicle?.plateNo ?? null;
    },
    vehicleType() {
      return this.driver?.vehicle?.type ?? null;
    },
    vehicleName() {
      return this.vehicleType != null ? VehicleTypes.get[this.vehicleType] : "";
    }
  }
};
</script>

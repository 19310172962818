<template>
  <div>
    <div class="font-weight-bold">ID: {{ serviceId }}</div>
    <div class="mt-4">
      <div class="h2 font-weight-bold">
        {{ service.progressText }}
      </div>
      <div class="text-secondary font-weight-semi-bold">
        Asignado el {{ acceptedAtDate }}
      </div>
    </div>
    <div class="mt-4 ml-5">
      <div class="checkpoint text-secondary" :class="[originStateClass]">
        <div class="dot"></div>
        <div class="line"></div>
        <div>
          <div class="font-weight-bold">
            {{ service.origin.destName }}
          </div>
          <div>
            {{ service.origin.address }}
          </div>
        </div>
        <div v-if="originCheckpoints.arrived" class="mt-3 d-flex">
          <div>{{ originCheckpoints.arrived }}</div>
          <div class="ml-3">
            El mensajero llegó a {{ service.origin.destName }}
          </div>
        </div>
        <div v-if="originCheckpoints.delivered" class="d-flex">
          <div>{{ originCheckpoints.delivered }}</div>
          <div class="ml-3">
            El mensajero recibió tu paquete
            <span v-if="originCheckpoints.signature">
              (firmado)
            </span>
          </div>
        </div>
      </div>

      <div
        v-if="hasArrivedToOrigin"
        class="checkpoint mt-3 text-secondary"
        :class="[destinationStateClass]"
      >
        <div class="dot"></div>
        <div class="line"></div>
        <div>
          <div class="font-weight-bold">
            {{ destinationName }}
          </div>
          <div>
            {{ destinationAddress }}
          </div>
        </div>
        <div v-if="destinationCheckpoints.arrived" class="mt-3 d-flex">
          <div>{{ destinationCheckpoints.arrived }}</div>
          <div class="ml-3">El mensajero llegó a {{ destinationName }}</div>
        </div>
        <div v-if="destinationCheckpoints.delivered" class="d-flex">
          <div>{{ destinationCheckpoints.delivered }}</div>
          <div class="ml-3">
            El mensajero entregó el paguete
            <span v-if="destinationCheckpoints.signature">
              (firmado)
            </span>
          </div>
        </div>
      </div>

      <div
        class="checkpoint mt-3 text-secondary"
        :class="{ visited: isServiceFinished }"
      >
        <div class="dot"></div>
        <div v-if="isServiceFinished">
          <div class="text-main font-weight-bold">
            Entregado
          </div>
          <div class="d-flex text-dark">
            <div>{{ serviceFinishedTime }}</div>
            <div class="ml-3">
              El paquete fue entregado
            </div>
          </div>
        </div>
        <div v-else-if="hasETA" class="mt-3">Llega {{ etaDate }}</div>
        <div v-else>Calculando hora de entrega</div>
      </div>
    </div>
  </div>
</template>

<script>
import { makeShortUUID } from "@/utils/strings";
import { formatISO } from "@zubut/common/src/utils/time";
import isSameDay from "date-fns/isSameDay";
import parseISO from "date-fns/parseISO";
import ServiceStatus from "@/constants/services/status";

export default {
  name: "Tracking",

  props: {
    service: {
      type: Object,
      default: null
    }
  },

  computed: {
    serviceId() {
      return this.service ? makeShortUUID(this.service.id) : "";
    },
    acceptedAtDate() {
      return formatISO(this.service.acceptedAt, "EEEE d MMM h:mm aaa");
    },
    hasETA() {
      return this.service.eta != null;
    },
    etaDate() {
      if (!this.hasETA) return "";
      const eta = this.service.eta;
      if (isSameDay(new Date(), parseISO(eta))) {
        return formatISO(this.service.eta, "'hoy alrededor de las' h:mm aaa");
      }
      return formatISO(this.service.eta, "EEEE d MMM h:mm aaa");
    },
    destinations() {
      const destination =
        this.service.nextDestination || this.service.lastCheckpoint;
      let destinations = [this.service.origin];
      if (destination) destinations.push(destination);
      return destinations;
    },
    isServiceFinished() {
      return this.service
        ? ServiceStatus.NUM_FINISHED === this.service.status
        : false;
    },
    serviceFinishedTime() {
      return (
        this.destinationCheckpoints.delivered ??
        formatISO(this.service.finishedAt, "h:mm aaa")
      );
    },
    originCheckpoints() {
      return this.formatCheckpoint(this.service.checkpoints[0]);
    },
    destinationCheckpoints() {
      const length = this.service.checkpoints.length;
      const lastCheckpoint = length <= 1 ? 1 : length - 1;
      return this.formatCheckpoint(this.service.checkpoints[lastCheckpoint]);
    },
    destinationName() {
      return this.service.nextDestination
        ? this.service.nextDestination?.destName
        : this.service.lastCheckpoint?.destName;
    },
    destinationAddress() {
      return this.service.nextDestination
        ? this.service.nextDestination?.address
        : this.service.lastCheckpoint?.address;
    },
    originStateClass() {
      if (
        (this.originCheckpoints.arrived && this.originCheckpoints.delivered) ||
        this.isServiceFinished
      ) {
        return "visited";
      }
      return "active";
    },
    destinationStateClass() {
      if (
        (this.destinationCheckpoints.arrived &&
          this.destinationCheckpoints.delivered) ||
        this.isServiceFinished
      ) {
        return "visited";
      }
      return this.originStateClass === "visited" ? "active" : "";
    },
    hasArrivedToOrigin() {
      if (this.originCheckpoints.arrived || this.isServiceFinished) {
        return true;
      }
      return false;
    }
  },

  methods: {
    formatCheckpoint(checkpoint) {
      if (checkpoint) {
        const { arrived, delivered, signature } = checkpoint;
        return {
          arrived: arrived ? formatISO(arrived, "h:mm aaa") : null,
          delivered: delivered ? formatISO(delivered, "h:mm aaa") : null,
          signature
        };
      } else {
        return {};
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.checkpoint {
  position: relative;

  .dot {
    position: absolute;
    top: 5px;
    left: calc(-1.5em - 10px);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: $gainsboro;
  }

  .line {
    position: absolute;
    height: calc(100% + 5px);
    width: 2px;
    background-color: $gainsboro;
    top: 15px;
    left: calc(-1.5em - 6px);
  }
}

.checkpoint.active {
  .dot {
    top: 0px;
    left: calc(-1.5em - 14px);
    width: 18px;
    height: 18px;
    border: 2px solid rgba(1, 119, 253, 0.5);
    background: transparent;

    &::after {
      position: absolute;
      content: "";
      top: 2px;
      left: 2px;
      width: 10px;
      height: 10px;
      background: var(--primary);
      border-radius: 50%;
    }
  }

  .line {
    top: 18px;
    height: calc(100% + 1px);
  }
}

.checkpoint.visited {
  .dot,
  .line {
    background: var(--primary);
  }
}
</style>

<template>
  <b-modal
    :id="id"
    modal-class="z-modal"
    :size="size"
    header-class="z-modal-header"
    :centered="centered"
    content-class="z-modal-content m-4 m-sm-0"
    :dialog-class="`z-modal-dialog ${dialogClass}`"
    :body-class="bodyClass"
    :hide-backdrop="hideBackdrop"
    hide-footer
    hide-header-close
    :no-close-on-backdrop="noCloseOnBackdrop"
    :no-close-on-esc="noCloseOnEsc"
    :show="show"
    :visible="show"
    @hidden="hideModal"
  >
    <slot />
    <template v-if="!hideClose" #modal-header>
      <div
        data-test-id="close-btn"
        role="button"
        class="close-btn"
        @click="hideModal"
      >
        <close-icon />
      </div>
    </template>
  </b-modal>
</template>

<script>
import CloseIcon from "../assets/icons/ic_close.svg";

export default {
  name: "ZModal",

  components: {
    CloseIcon
  },

  props: {
    id: {
      type: String,
      default: "z-modal"
    },
    bodyClass: {
      type: String,
      default: "p-5"
    },
    centered: {
      type: Boolean,
      default: false
    },
    dialogClass: {
      type: String,
      default: ""
    },
    hideBackdrop: {
      type: Boolean,
      default: false
    },
    hideClose: {
      type: Boolean,
      default: false
    },
    noCloseOnBackdrop: {
      type: Boolean,
      default: false
    },
    noCloseOnEsc: {
      type: Boolean,
      default: false
    },
    show: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: "xl"
    }
  },

  methods: {
    hideModal() {
      this.$emit("update:show", false);
      this.$emit("hide");
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .z-modal {
  .z-modal-content {
    border-radius: 10px;
    border: none;
  }

  .z-modal-header {
    position: absolute;
    top: -68px;
    right: 0px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-right: 0px;
    background-color: transparent;
  }

  .z-modal-dialog {
    margin-top: 4rem;
  }

  @media (min-width: 576px) {
    .modal-xl {
      max-width: 95%;
    }
  }
}
</style>

<style lang="scss">
.modal-backdrop {
  background-color: rgb(30, 30, 30) !important;
}
</style>

<template>
  <div>
    <div class="parcel-header">
      <div class="container">
        <b-row>
          <b-col cols="12" md="4">
            <div class="font-weight-bold">Fecha de envío</div>
            <div class="subtitle">{{ createdAt }}</div>
          </b-col>
          <b-col v-if="externalId" cols="12" md="4" class="mt-3 mt-md-0">
            <div class="font-weight-bold">Clave de rastreo</div>
            <div class="subtitle">{{ externalId }}</div>
          </b-col>
          <b-col cols="12" md="4" class="mt-3 mt-md-0">
            <div class="font-weight-bold">No. Guía</div>
            <div class="subtitle">{{ parcel.id }}</div>
          </b-col>
        </b-row>
      </div>
    </div>
    <div v-if="isLoading">
      <loading-spinner></loading-spinner>
    </div>
    <div v-else class="container">
      <b-row>
        <b-col cols="12" md="5">
          <div class="py-4">
            <div class="h5 font-weight-bold">
              Entregar a:
            </div>
            <div class="h5 font-weight-bold mt-4">
              {{ getName }}
            </div>
            <div>{{ address }}</div>
            <div v-if="isParcelDelivered">
              <div class="h5 font-weight-bold mt-4">
                Evidencias de entrega:
              </div>
              <p class="font-weight-bold mt-4">Recibió:</p>
              <p>{{ receiverText }}: {{ deliveryProof.receiverName }}</p>
              <div
                v-if="deliveryProof.imageUrl"
                class="image-wrapper"
                @click="showFullImage = true"
              >
                <img :src="deliveryProof.imageUrl" alt="evidencia" />
              </div>
            </div>
          </div>
        </b-col>
        <b-col cols="12" md="7">
          <div class="mt-4">
            <div class="h5 font-weight-bold">
              {{ currentEvent }}
              <font-awesome-icon
                v-show="isParcelDelivered"
                v-tooltip.top="'Descargar evidencia'"
                icon="sign-file"
                class="sign-icon"
                @click="downloadEvidence"
              />
            </div>
            <div
              v-show="!isParcelFinished"
              class="text-secondary font-weight-semi-bold"
            >
              {{ deliveryMessage }}
            </div>
          </div>
          <div class="mt-4 ml-5">
            <tracking-point
              v-for="(event, index) in parsedEvents"
              :key="`event-${index}`"
              :title="event.title"
              :subtitle="event.subtitle"
              :state="event.state"
              :is-last="index === parsedEvents.length - 1"
            />
          </div>
        </b-col>
      </b-row>
      <z-modal
        :show="showFullImage"
        size="md"
        centered
        hide-close
        @update:show="showFullImage = $event"
      >
        <img
          v-if="deliveryProof"
          class="modal-image"
          :src="deliveryProof.imageUrl"
          alt="evidencia"
        />
      </z-modal>
    </div>
  </div>
</template>

<script>
import ZModal from "@zubut/common/src/components/ZModal";
import ParcelLogs from "@zubut/common/src/constants/parcels/logs";
import ParcelStatus from "@zubut/common/src/constants/parcels/status";
import ReceiverType from "@zubut/common/src/constants/proofOfDelivery/receiver";
import { formatISO, diffInDays } from "@zubut/common/src/utils/time";
import TrackingPoint from "./TrackingPoint";
import notifyMixin from "@/mixins/notify";
import Parcels from "@/services/parcels";

export default {
  name: "ParcelTracking",

  components: {
    TrackingPoint,
    ZModal
  },

  mixins: [notifyMixin("notifications")],

  props: {
    parcel: { type: Object, default: () => {} },
    loading: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      date: "",
      showFullImage: false,
      lastEvent: "",
      parcelLogs: ParcelLogs
    };
  },

  computed: {
    isLoading() {
      return this.loading;
    },
    deliveryProof() {
      return this.parcel.deliveryProof;
    },
    externalId() {
      if (this.parcel.externalId) {
        return this.parcel.externalId;
      }
      return null;
    },

    getName() {
      if (this.parcel.destination) {
        return this.parcel.destination.name;
      }
      return "";
    },

    receiverText() {
      return ReceiverType.get[this.deliveryProof.receiverType]
        ? ReceiverType.get[this.deliveryProof.receiverType]
        : "";
    },

    address() {
      if (this.parcel.destination) {
        return this.parcel.destination.address;
      }
      return "";
    },

    createdAt() {
      if (this.date) return formatISO(this.date, "eeee d 'de' MMMM");
      else return "";
    },

    currentEvent() {
      return this.lastEvent;
    },

    parsedEvents() {
      const events = [];
      if (this.parcel.parcelLogs) {
        const logs = this.parcel.parcelLogs;
        logs.forEach(log => {
          if (log.event === 0) {
            this.date = log.createdAt;
          }
          events.push({
            title: ParcelLogs.get[log.event],
            subtitle: formatISO(log.createdAt, "dd/MM/yy hh:mm aaa"),
            state: "visited"
          });
        });
        events[events.length - 1].state = "active";
        this.setLastEvent(events[events.length - 1].title);
        if (![7, 13, 14, 15].includes(logs[logs.length - 1].event)) {
          events.push({
            title: "Fecha de entrega estimada",
            subtitle: formatISO(
              this.parcel.deliveryDate,
              "dd/MM/yy 'Antes de las 8:00 PM'"
            )
          });
        }
      }

      return events;
    },

    deliveryMessage() {
      const days = diffInDays(new Date(), this.parcel.deliveryDate);
      if (days === 0) return "Tu pedido llega Hoy antes de las 8:00 PM";
      else if (days === 1) return "Tu pedido llega Mañana antes de las 8:00 PM";
      else
        return formatISO(
          this.parcel.deliveryDate,
          "'Tu pedido llega el' EEEE d 'de' MMMM 'antes de las 8:00 PM'"
        );
    },

    isParcelFinished() {
      return this.parcel
        ? this.parcel.status === ParcelStatus.NUM_RETURNED_TO_ORIGIN ||
            this.parcel.status === ParcelStatus.NUM_PENDING_RETURN_TO_ORIGIN ||
            this.parcel.status === ParcelStatus.NUM_RETURNING_TO_ORIGIN ||
            this.parcel.status ===
              ParcelStatus.NUM_RETURNING_TO_CLIENT_WAREHOUSE ||
            this.parcel.status === ParcelStatus.NUM_CANCELLED ||
            this.parcel.status === ParcelStatus.NUM_DELIVERED
        : false;
    },

    isParcelDelivered() {
      return this.parcel?.status === ParcelStatus.NUM_DELIVERED;
    }
  },

  methods: {
    setLastEvent(event) {
      this.lastEvent = event;
    },

    downloadEvidence() {
      Parcels.downloadProofOfDelivery(this.parcel.id)
        .then(response => {
          const blob = new Blob([response]);
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = `${this.parcel.id}.pdf`;
          link.click();
          this.notify({
            type: "success",
            title: "Evidencia descargada"
          });
        })
        .catch(err => {
          this.$captureError(err);
          console.error(err);
          this.notify({
            type: "error",
            title: "Error al dercagar evidencia"
          });
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.image-wrapper {
  cursor: pointer;
  display: inline-block;
  border: 2px solid $white-liac;
  border-radius: 6px;
  padding: 6px;
  img {
    border-radius: 6px;
    max-height: 60px;
    max-width: 60px;
  }
}

.modal-image {
  display: block;
  width: 300px;
  margin: 0 auto;
}

.parcel-header {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  background-color: $regal-blue;
  color: white;
  margin-bottom: 0.5rem;
}

.sign-icon:hover {
  color: var(--primary);
  cursor: pointer;
}

@media (min-width: 768px) {
  .parcel-header {
    margin-bottom: 1.5rem;
  }

  .title {
    font-weight: 600;
  }

  .subtitle {
    font-size: 1.25em;
  }
}
</style>

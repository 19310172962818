<template>
  <div class="checkpoint mt-3 text-secondary" :class="state">
    <div class="dot"></div>
    <div v-if="!isLast" class="line"></div>
    <div>
      <div class="font-weight-bold">
        {{ title }}
      </div>
    </div>
    <div>
      {{ subtitle }}
    </div>
  </div>
</template>

<script>
export default {
  name: "TrackingPoint",

  props: {
    state: {
      type: String,
      validator: val => ["active", "visited", ""].indexOf(val) > -1,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    subtitle: {
      type: String,
      default: ""
    },
    isLast: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.checkpoint {
  position: relative;

  .dot {
    position: absolute;
    top: 5px;
    left: calc(-1.5em - 10px);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: $gainsboro;
  }

  .line {
    position: absolute;
    height: calc(100% + 5px);
    width: 2px;
    background-color: $gainsboro;
    top: 15px;
    left: calc(-1.5em - 6px);
  }
}

.checkpoint.active {
  color: $nero !important;
  .dot {
    top: 0px;
    left: calc(-1.5em - 14px);
    width: 18px;
    height: 18px;
    border: 2px solid rgba(1, 119, 253, 0.5);
    background: transparent;

    &::after {
      position: absolute;
      content: "";
      top: 2px;
      left: 2px;
      width: 10px;
      height: 10px;
      background: var(--primary);
      border-radius: 50%;
    }
  }

  .line {
    top: 18px;
    height: calc(100% + 1px);
  }
}

.checkpoint.visited {
  .dot,
  .line {
    background: var(--primary);
  }
}
</style>

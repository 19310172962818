const SERVICE = "service";
const PACKAGE = "package";
const PARCEL = "parcel";

const NUM_SERVICE = 0;
const NUM_PACKAGE = 1;
const NUM_PARCEL = 2;

const get = {};
get[NUM_SERVICE] = SERVICE;
get[NUM_PACKAGE] = PACKAGE;
get[NUM_PARCEL] = PARCEL;
get[SERVICE] = NUM_SERVICE;
get[PACKAGE] = NUM_PACKAGE;
get[PARCEL] = NUM_PARCEL;

export default {
  get,
  SERVICE,
  PACKAGE,
  PARCEL,
  NUM_SERVICE,
  NUM_PACKAGE,
  NUM_PARCEL
};
